.container {
  .notFoundWrapper {
    padding-top: 210px;
    text-align: center;

    .notFoundTitle {
      font-size: 60px;
      font-weight: 700;
    }

    .notFoundDescription {
      font-size: 18px;
      font-weight: 700;
      color: rgba(0,0,0,.5);
    }

    .notFoundBackHomeButton {
      padding-top: 35px;
    }
  }
}
